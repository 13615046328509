import styled from 'styled-components'

export const Wrapper = styled.section`
  display: flex;
  justify-content: space-between;
  padding: 50px 165px;
  align-items: center;
  gap: 50px;

  .screen {
    width: 40%;
    display: flex;
    justify-content: flex-end;

    .gatsby-image-wrapper {
      width: 70%;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 60%;

    .title {
      font-size: 32px;
      font-family: 'Poppins-Bold';
    }

    .description {
      font-size: 16px;
      line-height: 24px;
      color: #636369;
      width: 50%;
      font-family: 'Poppins';
    }

    .buttons {
      margin-top: 10px;

      display: flex;
      gap: 30px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wl}) {
    padding: 50px 100px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wm}) {
    padding: 50px 50px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    padding: 50px 20px;
    flex-direction: column-reverse;
    .content .description {
      width: 100%;
    }
    .screen {
      width: 100%;
      display: flex;
      justify-content: center;

      .gatsby-image-wrapper {
        width: 80%;
      }
    }
    .content {
      width: 100%;

      .title {
        text-align: center;
      }
      .description {
        text-align: center;
      }
    }

    .responsive {
      display: flex;
      width: 100%;
      justify-content: center;
    }

    & > .link,
    & > .link .custom-button {
      width: 100%;
      margin-block: -7px;
    }
  }
`
