import {
  FlexArticle,
  InnerArticle,
  StyledButton,
  StyledText,
  WaveWrapper,
} from 'components/common'
import React from 'react'
import {
  IntroduceArticle,
  Wrapper,
} from './styled-components'

import WebsiteWomanSvg from 'images/svg/websiteWomanReversed.svg'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { FlexArticleContainer } from 'sections/HomePage/Features/styled-componens'
import { currentIconEnums } from 'interfaces/FlexArticleInterfaces'

const JoinCommunitySection = ({
  title,
  description,
  innerArticleTexts,
  articles,
}: {
  title: string
  description: string
  innerArticles: any
  innerArticleTexts: string[]
  articles: {
    title: string
    description: string
  }[]
}) => {
  const SvgData = useStaticQuery(DESKTOP_SVG_QUERY).file
    .childImageSharp.fluid
  const articleIconEnums = [
    currentIconEnums.flag,
    currentIconEnums.share,
    currentIconEnums.videoCam,
    currentIconEnums.wavesReverse,
  ]
  return (
    <WaveWrapper color="lightGrey">
      <Wrapper>
        <IntroduceArticle>
          <div className="first-child">
            <StyledText className="heading">
              {title}
            </StyledText>
            <div className="responsive">
              <Img fluid={SvgData} alt="Website" />
            </div>
            <StyledText
              fontType="semibold"
              textColor="#636369"
              fontWeight={400}
              fontSize="16px"
            >
              {description}
            </StyledText>
            {innerArticleTexts.map((text, index) => (
              <InnerArticle
                priority="low"
                margins={[8, 0]}
                iconWidth={20}
                key={index}
              >
                {text}
              </InnerArticle>
            ))}
          </div>
          <div
            className="responsive-top"
            style={{ marginBlock: 40 }}
          >
            <WebsiteWomanSvg />
          </div>
        </IntroduceArticle>
        <FlexArticleContainer>
          {articles.map((article, index) => {
            return (
              <FlexArticle
                width="20%"
                key={index}
                icon={
                  articleIconEnums[
                    index % articleIconEnums.length
                  ]
                }
                title={article.title}
                description={article.description}
                gap={[16, 8]}
              />
            )
          })}
        </FlexArticleContainer>
        <StyledButton
          to="https://calendly.com/suada-david-thomson/suada-platform-demonstration"
          paddings={74}
        >
          See it in action
        </StyledButton>
      </Wrapper>
    </WaveWrapper>
  )
}

export default JoinCommunitySection

const DESKTOP_SVG_QUERY = graphql`
  query {
    file(relativePath: { eq: "images/svg/desktop.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
