import { graphql } from 'gatsby'
import defineHtmlAst from 'helpers/defineHtmlAst'
import indexAscendingSort from 'helpers/indexAscendingSort'
import { ILearnersPageProps } from 'interfaces/PageInterfaces'
import React from 'react'
import { Helmet } from 'react-helmet'
import { TrustedCompaniesSection } from 'sections/Businesses'
import {
  BannerSection,
  ExploreCoursesSection,
  JoinCommunitySection,
  MasterKeySection,
  TrainingSection,
} from 'sections/Learners'

const Learners = ({ data }: ILearnersPageProps) => {
  const {
    bannerText,
    partners,
    exploreCoursesTexts,
    masterKeyTexts,
    joinCommunityTexts,
    featuresArticles,
    onDemandTexts,
  } = data
  masterKeyTexts.articles = [
    {
      title: masterKeyTexts.block3MasterArticle1Title,
      description:
        masterKeyTexts.block3MasterArticle1Description
          .block3MasterArticle1Description,
    },
    {
      title: masterKeyTexts.block3MasterArticle2Title,
      description:
        masterKeyTexts.block3MasterArticle2Description
          .block3MasterArticle2Description,
    },
    {
      title: masterKeyTexts.block3MasterArticle3Title,
      description:
        masterKeyTexts.block3MasterArticle3Description
          .block3MasterArticle3Description,
    },
    {
      title: masterKeyTexts.block3MasterArticle4Title,
      description:
        masterKeyTexts.block3MasterArticle4Description
          .block3MasterArticle4Description,
    },
  ]
  joinCommunityTexts.innerArticleTexts = defineHtmlAst(
    joinCommunityTexts.innerArticles,
  )

  const featuresArticlesSorted = featuresArticles.nodes
    .sort(indexAscendingSort)
    .slice(0, 3)

  return (
    <>
      <Helmet title="Learners" defer={false} />
      <BannerSection {...bannerText} />
      <ExploreCoursesSection {...exploreCoursesTexts} />
      <MasterKeySection
        header={masterKeyTexts.header}
        subHeader={masterKeyTexts.subHeader}
        articles={masterKeyTexts.articles}
      />
      <JoinCommunitySection
        {...joinCommunityTexts}
        articles={featuresArticlesSorted}
      />
      <TrainingSection {...onDemandTexts} />
      <TrustedCompaniesSection partners={partners.nodes} />
    </>
  )
}

export default Learners

export const pageData = graphql`
  query {
    bannerText: contentfulLearnersPage {
      header: block1BannerHeader
      subHeader: block1BannerSubHeader
    }
    partners: allContentfulPartnerCompanies {
      nodes {
        logo {
          gatsbyImageData(width: 100, quality: 80)
        }
      }
    }
    exploreCoursesTexts: contentfulLearnersPage {
      header: block2CourseTitle
      title: block2CoursesTitle
      description: block2CourseDescription
    }
    masterKeyTexts: contentfulLearnersPage {
      block3MasterArticle1Title
      block3MasterArticle1Description {
        block3MasterArticle1Description
      }
      block3MasterArticle2Description {
        block3MasterArticle2Description
      }
      block3MasterArticle2Title
      block3MasterArticle3Title
      block3MasterArticle3Description {
        block3MasterArticle3Description
      }
      block3MasterArticle4Title
      block3MasterArticle4Description {
        block3MasterArticle4Description
      }
      subHeader: block3MasterDescription
      header: block3MasterTitle
    }
    joinCommunityTexts: contentfulLearnersPage {
      description: block4CommunityDescription
      title: block4CommunityTitle
      innerArticles: block4CommunityInnerArticles {
        childrenMarkdownRemark {
          htmlAst
        }
      }
    }
    featuresArticles: allContentfulHomePageFeaturesArticle {
      nodes {
        title
        description
        index
      }
    }
    onDemandTexts: contentfulLearnersPage {
      title: block5OnDemandTitle
      description: block5OnDemandDescription {
        description: block5OnDemandDescription
      }
    }
  }
`
