import { StyledButton } from 'components/common'

import React from 'react'
import { Wrapper } from './styled-components'

import AppStoreIcon from 'images/svg/app-store.svg'
import PlayStoreIcon from 'images/svg/play-store.svg'

import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { SingleShortArticleProps } from 'interfaces/PageInterfaces'
import { isValidElement } from 'helpers/defineHtmlAst'

const TrainingSection = ({
  title,
  description,
}: SingleShortArticleProps) => {
  const skillTestVideoFluid =
    useStaticQuery(STV_QUERY).file.childImageSharp.fluid
  return (
    <Wrapper>
      <StyledButton
        to={
          'https://apps.apple.com/fi/app/suada/id1526722110'
        }
        className="responsive-xs"
        color="#06C68F"
      >
        <AppStoreIcon />
        App Store
      </StyledButton>
      <StyledButton
        to={
          'https://play.google.com/store/apps/details?id=com.suada&hl=en&gl=US'
        }
        className="responsive-xs"
        color="#06C68F"
      >
        <PlayStoreIcon />
        Play Store
      </StyledButton>

      <div className="screen">
        <Img
          fluid={skillTestVideoFluid}
          alt="skillTestVideo"
        />
      </div>
      <div className="content">
        <h6 className="title heading">{title}</h6>
        {isValidElement(description) && (
          <p className="description">
            {description.description}
          </p>
        )}
        <div className="buttons responsive-top">
          <StyledButton
            color="#06C68F"
            to={
              'https://play.google.com/store/apps/details?id=com.suada&hl=en&gl=US'
            }
          >
            <PlayStoreIcon />
            Play Store
          </StyledButton>
          <StyledButton
            color="#06C68F"
            to={
              'https://apps.apple.com/fi/app/suada/id1526722110'
            }
          >
            <AppStoreIcon />
            App Store
          </StyledButton>
        </div>
      </div>
    </Wrapper>
  )
}

export default TrainingSection

const STV_QUERY = graphql`
  query STVImage {
    file(
      relativePath: {
        eq: "images/screenshots/skill-test-video.png"
      }
    ) {
      relativePath
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
