import { StyledButton, StyledText } from 'components/common'
import React from 'react'
import {
  ArticleContainer,
  ArticleDescription,
  ArticleIcon,
  ArticleTitle,
  FlexArticleContainer,
  FlexArticleItem,
  Wrapper,
} from './styled-components'

import Articles from './Articles'
import { CustomCarousel } from 'components/carousels'

import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { isValidElement } from 'helpers/defineHtmlAst'
import GroupSvg from 'images/svg/group.svg'
import HeadsetSvg from 'images/svg/headset.svg'
import DownloadSpeedSvg from 'images/svg/download-speed.svg'
import ImprovementSvg from 'images/svg/improvement.svg'

const MasterKeySection = ({
  header,
  subHeader,
  articles,
}: {
  header: string
  subHeader: string
  articles: {
    title: string
    description: string
  }[]
}) => {
  const articleSvgs = [
    <GroupSvg />,
    <HeadsetSvg />,
    <DownloadSpeedSvg />,
    <ImprovementSvg />,
  ]
  const skillTestVideoFluid =
    useStaticQuery(STV_QUERY).file.childImageSharp.fluid
  return (
    <Wrapper>
      <StyledText
        textAlign="center"
        className="heading master-key"
      >
        {header}
      </StyledText>
      <ArticleContainer>
        <div className="content responsive-percent">
          <div className="title">
            <p className="percentage">90%</p>
            <p className="label">
              Learning
              <br />
              retention
            </p>
          </div>
        </div>
        <div className="screen">
          <Img
            fluid={skillTestVideoFluid}
            alt="skillTestVideo"
          />
        </div>
        <div className="content  responsive-invert">
          <div className="title">
            <p className="percentage">90%</p>
            <p className="label">
              Learning
              <br />
              retention
            </p>
          </div>
          {isValidElement(subHeader) && (
            <p className="description">{subHeader}</p>
          )}
          <StyledButton
            to="https://calendly.com/suada-david-thomson/suada-platform-demonstration"
            paddings={74}
          >
            See it in action
          </StyledButton>
        </div>
      </ArticleContainer>
      <FlexArticleContainer>
        {articles.map((article, index) => (
          <FlexArticleItem key={index}>
            <ArticleIcon>{articleSvgs[index]}</ArticleIcon>
            <ArticleTitle>{article.title}</ArticleTitle>
            <ArticleDescription>
              {article.description}
            </ArticleDescription>
          </FlexArticleItem>
        ))}
      </FlexArticleContainer>
      <CustomCarousel articles={Articles} />
    </Wrapper>
  )
}

export default MasterKeySection

const STV_QUERY = graphql`
  query skillTestVideoImage {
    file(
      relativePath: {
        eq: "images/screenshots/skill-test-video.png"
      }
    ) {
      relativePath
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
