import styled from 'styled-components'

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 165px 40px 165px;
  gap: 56px;
  ${({ theme }) => theme.placeholders.maxWidth}

  @media (max-width: ${({ theme }) => theme.breakpoints.wl}) {
    padding: 100px 100px 40px 100px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wm}) {
    padding: 40px 50px 20px 50px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    padding: 30px 20px 20px 20px;

    .link,
    .custom-button {
      width: 100%;
    }
  }
`

export const IntroduceArticle = styled.article`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.ws}) {
    .first-child {
      width: 50%;
    }
  }

  .first-child .responsive {
    margin-block: 40px;

    .gatsby-image-wrapper {
      margin: 0 auto;
      width: 90%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.ws}) {
    flex-direction: column;

    .first-child {
      width: 100% !important;
    }

    & > .heading {
      text-align: center;
    }

    & > .subheader {
      wid
    }

    & > svg {
      width: 90%;
    }
  }
`
