import { CustomCarousel } from 'components/carousels'
import { StyledButton, StyledText } from 'components/common'
import { Link } from 'gatsby'
import React from 'react'
import Articles from './ArticleTexts'
import {
  ArticleItem,
  ArticleContainer,
  Wrapper,
  SalesMasteryContainer,
  CourseDetails,
  CourseSvg,
} from './styled-components'
import GraphSvg from 'images/svg/graph-final.svg'
import { ExploreCoursesProps } from 'interfaces/PageInterfaces'
import { isValidElement } from 'helpers/defineHtmlAst'

const ExploreCoursesSection = ({
  header,
  title,
  description,
}: ExploreCoursesProps) => {
  return (
    <Wrapper>
      <StyledText className="heading">{header}</StyledText>
      {/* <ArticleContainer>
        {Articles.map(({ title, description, route }, index) => {
          return (
            <Link to={route}>
              <ArticleItem key={index}>
                <p className="title">{title}</p>
                <p className="description">{description}</p>
              </ArticleItem>
            </Link>
          )
        })}
      </ArticleContainer> */}
      <SalesMasteryContainer>
        <CourseDetails>
          <StyledText className="heading">
            {title}
          </StyledText>
          {isValidElement(description) && (
            <StyledText className="description">
              {description}
            </StyledText>
          )}
          <StyledButton link="SalesMastery">
            Learn More
          </StyledButton>
        </CourseDetails>
        <CourseSvg>
          <GraphSvg />
        </CourseSvg>
      </SalesMasteryContainer>
      {/* <CustomCarousel variant={'explore-courses'} articles={Articles} /> */}
    </Wrapper>
  )
}

export default ExploreCoursesSection
