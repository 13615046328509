import GroupSvg from 'images/svg/group.svg'
import HeadsetSvg from 'images/svg/headset.svg'
import DownloadSpeedSvg from 'images/svg/download-speed.svg'
import ImprovementSvg from 'images/svg/improvement.svg'

const Articles = [
  {
    icon: GroupSvg,
    title: '3x faster learning',
    description: `Techniques known to speed up learning & boost retention are built
    into the platform.`,
    list: [
      `• Chunk study sessions`,
      `• Frequent quizzing`,
      `• Note taking`,
      `• Skills practice`,
      `• Say is out loud`,
      `• Teach someone else`,
    ],
  },
  {
    icon: HeadsetSvg,
    title: 'Performance support',
    description: `90% of learning happens after the modules with support on the job.

    24/7 access to content, feedback and mentoring promotes:`,
    list: [
      '• Concept mastery',
      '• On the job competency',
      '• Continuous improvement',
    ],
  },
  {
    icon: DownloadSpeedSvg,
    title: 'Supports for all learners',
    description: `We are all wired differently. Suada app recognizes and supports
    neurodiverse learners with:`,
    list: [
      '• Short modules',
      '• Transcripts',
      '• Flashcards',
      '• Course notes',
      '• Adaptive assignments',
    ],
  },
  {
    icon: ImprovementSvg,
    title: 'Optimized automations',
    description: `Nothing gets missed with in-app reminders of what you need to do and
    when you need to do it.`,
    list: [
      '• Upcoming training',
      '• Aproaching deadline',
      '• Notifications on progress',
      '• Promts to continue',
      '• Course changes and updates',
    ],
  },
]

export default Articles
