export default function indexAscendingSort(
  a: indexNumber,
  b: indexNumber,
) {
  return a.index - b.index
}

interface indexNumber {
  index: number
}
